[
  ["aa","aar","aar","ISO 639-1:aa"],
  ["ab","abk","abk","ISO 639-1:ab"],
  ["ae","ave","ave","ISO 639-1:ae"],
  ["af","afr","afr","ISO 639-1:af"],
  ["ak","aka","aka","ISO 639-1:ak"],
  ["am","amh","amh","ISO 639-1:am"],
  ["an","arg","arg","ISO 639-1:an"],
  ["ar","ara","ara","ISO 639-1:ar"],
  ["as","asm","asm","ISO 639-1:as"],
  ["av","ava","ava","ISO 639-1:av"],
  ["ay","aym","aym","ISO 639-1:ay"],
  ["az","aze","aze","ISO 639-1:az"],
  ["ba","bak","bak","ISO 639-1:ba"],
  ["be","bel","bel","ISO 639-1:be"],
  ["bg","bul","bul","ISO 639-1:bg"],
  ["bh","bih","bih","ISO 639-1:bh"],
  ["bi","bis","bis","ISO 639-1:bi"],
  ["bm","bam","bam","ISO 639-1:bm"],
  ["bn","ben","ben","ISO 639-1:bn"],
  ["bo","bod","tib","ISO 639-1:bo"],
  ["br","bre","bre","ISO 639-1:br"],
  ["bs","bos","bos","ISO 639-1:bs"],
  ["ca","cat","cat","ISO 639-1:ca"],
  ["ce","che","che","ISO 639-1:ce"],
  ["ch","cha","cha","ISO 639-1:ch"],
  ["co","cos","cos","ISO 639-1:co"],
  ["cr","cre","cre","ISO 639-1:cr"],
  ["cs","ces","cze","ISO 639-1:cs"],
  ["cu","chu","chu","ISO 639-1:cu"],
  ["cv","chv","chv","ISO 639-1:cv"],
  ["cy","cym","wel","ISO 639-1:cy"],
  ["da","dan","dan","ISO 639-1:da"],
  ["de","deu","ger","ISO 639-1:de"],
  ["dv","div","div","ISO 639-1:dv"],
  ["dz","dzo","dzo","ISO 639-1:dz"],
  ["ee","ewe","ewe","ISO 639-1:ee"],
  ["el","ell","gre","ISO 639-1:el"],
  ["en","eng","eng","ISO 639-1:en"],
  ["eo","epo","epo","ISO 639-1:eo"],
  ["es","spa","spa","ISO 639-1:es"],
  ["et","est","est","ISO 639-1:et"],
  ["eu","eus","baq","ISO 639-1:eu"],
  ["fa","fas","per","ISO 639-1:fa"],
  ["ff","ful","ful","ISO 639-1:ff"],
  ["fi","fin","fin","ISO 639-1:fi"],
  ["fj","fij","fij","ISO 639-1:fj"],
  ["fo","fao","fao","ISO 639-1:fo"],
  ["fr","fra","fre","ISO 639-1:fr"],
  ["fy","fry","fry","ISO 639-1:fy"],
  ["ga","gle","gle","ISO 639-1:ga"],
  ["gd","gla","gla","ISO 639-1:gd"],
  ["gl","glg","glg","ISO 639-1:gl"],
  ["gn","grn","grn","ISO 639-1:gn"],
  ["gu","guj","guj","ISO 639-1:gu"],
  ["gv","glv","glv","ISO 639-1:gv"],
  ["ha","hau","hau","ISO 639-1:ha"],
  ["he","heb","heb","ISO 639-1:he"],
  ["hi","hin","hin","ISO 639-1:hi"],
  ["ho","hmo","hmo","ISO 639-1:ho"],
  ["hr","hrv","hrv","ISO 639-1:hr"],
  ["ht","hat","hat","ISO 639-1:ht"],
  ["hu","hun","hun","ISO 639-1:hu"],
  ["hy","hye","arm","ISO 639-1:hy"],
  ["hz","her","her","ISO 639-1:hz"],
  ["ia","ina","ina","ISO 639-1:ia"],
  ["id","ind","ind","ISO 639-1:id"],
  ["ie","ile","ile","ISO 639-1:ie"],
  ["ig","ibo","ibo","ISO 639-1:ig"],
  ["ii","iii","iii","ISO 639-1:ii"],
  ["ik","ipk","ipk","ISO 639-1:ik"],
  ["io","ido","ido","ISO 639-1:io"],
  ["is","isl","ice","ISO 639-1:is"],
  ["it","ita","ita","ISO 639-1:it"],
  ["iu","iku","iku","ISO 639-1:iu"],
  ["ja","jpn","jpn","ISO 639-1:ja"],
  ["jv","jav","jav","ISO 639-1:jv"],
  ["ka","kat","geo","ISO 639-1:ka"],
  ["kg","kon","kon","ISO 639-1:kg"],
  ["ki","kik","kik","ISO 639-1:ki"],
  ["kj","kua","kua","ISO 639-1:kj"],
  ["kk","kaz","kaz","ISO 639-1:kk"],
  ["kl","kal","kal","ISO 639-1:kl"],
  ["km","khm","khm","ISO 639-1:km"],
  ["kn","kan","kan","ISO 639-1:kn"],
  ["ko","kor","kor","ISO 639-1:ko"],
  ["kr","kau","kau","ISO 639-1:kr"],
  ["ks","kas","kas","ISO 639-1:ks"],
  ["ku","kur","kur","ISO 639-1:ku"],
  ["kv","kom","kom","ISO 639-1:kv"],
  ["kw","cor","cor","ISO 639-1:kw"],
  ["ky","kir","kir","ISO 639-1:ky"],
  ["la","lat","lat","ISO 639-1:la"],
  ["lb","ltz","ltz","ISO 639-1:lb"],
  ["lg","lug","lug","ISO 639-1:lg"],
  ["li","lim","lim","ISO 639-1:li"],
  ["ln","lin","lin","ISO 639-1:ln"],
  ["lo","lao","lao","ISO 639-1:lo"],
  ["lt","lit","lit","ISO 639-1:lt"],
  ["lu","lub","lub","ISO 639-1:lu"],
  ["lv","lav","lav","ISO 639-1:lv"],
  ["mg","mlg","mlg","ISO 639-1:mg"],
  ["mh","mah","mah","ISO 639-1:mh"],
  ["mi","mri","mao","ISO 639-1:mi"],
  ["mk","mkd","mac","ISO 639-1:mk"],
  ["ml","mal","mal","ISO 639-1:ml"],
  ["mn","mon","mon","ISO 639-1:mn"],
  ["mr","mar","mar","ISO 639-1:mr"],
  ["ms","msa","may","ISO 639-1:ms"],
  ["mt","mlt","mlt","ISO 639-1:mt"],
  ["my","mya","bur","ISO 639-1:my"],
  ["na","nau","nau","ISO 639-1:na"],
  ["nb","nob","nob","ISO 639-1:nb"],
  ["nd","nde","nde","ISO 639-1:nd"],
  ["ne","nep","nep","ISO 639-1:ne"],
  ["ng","ndo","ndo","ISO 639-1:ng"],
  ["nl","nld","dut","ISO 639-1:nl"],
  ["nn","nno","nno","ISO 639-1:nn"],
  ["no","nor","nor","ISO 639-1:no"],
  ["nr","nbl","nbl","ISO 639-1:nr"],
  ["nv","nav","nav","ISO 639-1:nv"],
  ["ny","nya","nya","ISO 639-1:ny"],
  ["oc","oci","oci","ISO 639-1:oc"],
  ["oj","oji","oji","ISO 639-1:oj"],
  ["om","orm","orm","ISO 639-1:om"],
  ["or","ori","ori","ISO 639-1:or"],
  ["os","oss","oss","ISO 639-1:os"],
  ["pa","pan","pan","ISO 639-1:pa"],
  ["pi","pli","pli","ISO 639-1:pi"],
  ["pl","pol","pol","ISO 639-1:pl"],
  ["ps","pus","pus","ISO 639-1:ps"],
  ["pt","por","por","ISO 639-1:pt"],
  ["qu","que","que","ISO 639-1:qu"],
  ["rm","roh","roh","ISO 639-1:rm"],
  ["rn","run","run","ISO 639-1:rn"],
  ["ro","ron","rum","ISO 639-1:ro"],
  ["ru","rus","rus","ISO 639-1:ru"],
  ["rw","kin","kin","ISO 639-1:rw"],
  ["sa","san","san","ISO 639-1:sa"],
  ["sc","srd","srd","ISO 639-1:sc"],
  ["sd","snd","snd","ISO 639-1:sd"],
  ["se","sme","sme","ISO 639-1:se"],
  ["sg","sag","sag","ISO 639-1:sg"],
  ["si","sin","sin","ISO 639-1:si"],
  ["sk","slk","slo","ISO 639-1:sk"],
  ["sl","slv","slv","ISO 639-1:sl"],
  ["sm","smo","smo","ISO 639-1:sm"],
  ["sn","sna","sna","ISO 639-1:sn"],
  ["so","som","som","ISO 639-1:so"],
  ["sq","sqi","alb","ISO 639-1:sq"],
  ["sr","srp","srp","ISO 639-1:sr"],
  ["ss","ssw","ssw","ISO 639-1:ss"],
  ["st","sot","sot","ISO 639-1:st"],
  ["su","sun","sun","ISO 639-1:su"],
  ["sv","swe","swe","ISO 639-1:sv"],
  ["sw","swa","swa","ISO 639-1:sw"],
  ["ta","tam","tam","ISO 639-1:ta"],
  ["te","tel","tel","ISO 639-1:te"],
  ["tg","tgk","tgk","ISO 639-1:tg"],
  ["th","tha","tha","ISO 639-1:th"],
  ["ti","tir","tir","ISO 639-1:ti"],
  ["tk","tuk","tuk","ISO 639-1:tk"],
  ["tl","tgl","tgl","ISO 639-1:tl"],
  ["tn","tsn","tsn","ISO 639-1:tn"],
  ["to","ton","ton","ISO 639-1:to"],
  ["tr","tur","tur","ISO 639-1:tr"],
  ["ts","tso","tso","ISO 639-1:ts"],
  ["tt","tat","tat","ISO 639-1:tt"],
  ["tw","twi","twi","ISO 639-1:tw"],
  ["ty","tah","tah","ISO 639-1:ty"],
  ["ug","uig","uig","ISO 639-1:ug"],
  ["uk","ukr","ukr","ISO 639-1:uk"],
  ["ur","urd","urd","ISO 639-1:ur"],
  ["uz","uzb","uzb","ISO 639-1:uz"],
  ["ve","ven","ven","ISO 639-1:ve"],
  ["vi","vie","vie","ISO 639-1:vi"],
  ["vo","vol","vol","ISO 639-1:vo"],
  ["wa","wln","wln","ISO 639-1:wa"],
  ["wo","wol","wol","ISO 639-1:wo"],
  ["xh","xho","xho","ISO 639-1:xh"],
  ["yi","yid","yid","ISO 639-1:yi"],
  ["yo","yor","yor","ISO 639-1:yo"],
  ["za","zha","zha","ISO 639-1:za"],
  ["zh","zho","chi","ISO 639-1:zh"],
  ["zu","zul","zul","ISO 639-1:zu"]
]
