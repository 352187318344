/* eslint-disable @typescript-eslint/no-explicit-any */
import * as languages from '@cospired/i18n-iso-languages';
import { TranslateService } from '@ngx-translate/core';
import * as countries from 'i18n-iso-countries';
import { ELanguageCode } from './enums/country.enum';
import { LanguageTranslatedI } from '../models/country.model';
import { Observable, take } from 'rxjs';

export function createEnumMap<E extends Record<string, string>>(
  e: E
): Map<keyof E, E[keyof E]> {
  const keys = Object.keys(e) as Array<keyof E>;
  return new Map(keys.map((key) => [key, e[key]] as [keyof E, E[keyof E]]));
}

export function translateCountry(
  code: string,
  lang: string
): string | undefined {
  return countries.getName(code, lang);
}

export function translateLanguage(
  code: string,
  lang: string
): string | undefined {
  return languages.getName(code, lang);
}

// temporary solution I guess to be replace by dictionary
export function getLanguages(
  translateService: TranslateService
): LanguageTranslatedI[] {
  return languages
    .langs()
    .filter(
      (lang) =>
        // [ELanguageCode.EN.valueOf(), ELanguageCode.PL.valueOf()].includes(lang.toLocaleLowerCase())
        lang.toLocaleLowerCase() === ELanguageCode.EN ||
        lang.toLocaleLowerCase() === ELanguageCode.PL
    )
    .map((lang) => {
      return {
        code: lang,
        name: translateLanguage(lang, translateService.defaultLang),
      };
    });
}

export function translateByIndex(
  prefix: string,
  index: number,
  translateService: TranslateService,
  postfix?: string
): Observable<string> {
  return translateService
    .get(`${prefix}${index}${postfix ?? ''}`)
    .pipe(take(1));
}

// function to replace only last segment of url divided by slash
export function replaceLastUrlSegment(url: string, newSegment: string): string {
  const urlSegments = url.split('/');

  urlSegments.pop();
  urlSegments.push(newSegment);

  const newUrl = urlSegments.join('/');
  return newUrl;
}

export function getLastUrlSegment(url: string): string | undefined {
  return url.split('/').pop();
}

// function to flat json tree,
// it ignores leaf names ex:
// from => {parent: {child1: value1, child2: value2}}
// to => {child1: value1, child2: value2}
export function flattenObject(obj: any): { [key: string]: any } {
  function flatten(obj: any, parentKey = ''): { [key: string]: any } {
    return Object.keys(obj).reduce(
      (acc: { [key: string]: any }, key: string) => {
        const nestedKey = parentKey ? `${parentKey}.${key}` : key;
        if (
          obj[key] &&
          typeof obj[key] === 'object' &&
          !Array.isArray(obj[key])
        ) {
          const flattened = flatten(obj[key], nestedKey);
          Object.keys(flattened).forEach((nestedKey) => {
            acc[nestedKey] = flattened[nestedKey];
          });
        } else {
          const parts = nestedKey.split('.');
          const lastKey = parts[parts.length - 1];
          acc[lastKey] = obj[key];
        }
        return acc;
      },
      {}
    );
  }

  return flatten(obj);
}

export function isSessionStorageAvailable() {
  if (typeof sessionStorage !== 'undefined') {
    return true;
  } else {
    return false;
  }
}

export function getNestedProperty(object: any, propertyName: string): unknown {
  return propertyName
    .split('.')
    .reduce((acc, part) => acc && acc[part], object);
}

export function getImageSrcBase64(base64: string) {
  return `data:image/jpg;base64, ${base64}`;
}

export function getStringOrNull(value: unknown) {
  return value == null ? null : `${value}`;
}
